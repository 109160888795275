'use client';

import Link from 'next/link';
import LZUTF8 from 'lzutf8';
import { useFormik } from 'formik';
import { useMutation } from '@tanstack/react-query';
import { useRouter } from 'next/navigation';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-hot-toast';

import { useTranslation } from 'i18n/client';
import { FIELDS, VALIDATION_ERROR_CODES } from 'app/[locale]/login/constants/login-constants';
import { Button, Input } from 'components';
import { setSession } from 'core';
import { routesConstants, helpers } from 'common';
import { IRequestError, IUser } from 'common/types';
import { authAPI, userAPI } from 'api';
import { ILoginData } from 'api/auth/types';
import { validationSchema } from 'app/[locale]/login/validators/login-validator';

interface IProps {
    lang: string;
    redirectUrl?: string;
    setIsMFAForm: (_isMFAForm: boolean) => void;
    setAuthToken: (_token: string) => void;
}

const { username, password } = FIELDS;
const { loginAPI } = authAPI;
const { getUserDetailsAPI } = userAPI;
const { routes, prefixes } = routesConstants;
const { getAPIErrorCode } = helpers;

const LoginForm = ({ lang, redirectUrl, setAuthToken, setIsMFAForm }: IProps) => {
    const router = useRouter();
    const { t } = useTranslation(lang, 'login');

    const { mutate, isLoading } = useMutation(loginAPI, {
        onSuccess: async (data) => {
            const accessToken = data?.data?.accessToken || '';
            const refreshToken = data?.data?.refreshToken || '';

            const decoded: any = jwtDecode(refreshToken);

            if (decoded?.mfaRequired) {
                setIsMFAForm(true);
                setAuthToken(accessToken);
                return;
            }

            const response = await getUserDetailsAPI({
                Authorization: `Bearer ${accessToken}`,
            });
            const userDetails: IUser = response?.data;

            setSession(accessToken, refreshToken, userDetails);
            router.refresh();

            // if the user has phone verified, redirect to home page, else redirect
            // to the phone verification page to force him/her to verify the phone number
            if (userDetails.phoneVerified) {
                const path = redirectUrl ? decodeURIComponent(LZUTF8.decompress(redirectUrl, { inputEncoding: 'Base64' })) : `/${lang}${routes[prefixes.home].path}`;
                router.push(path);
            } else {
                router.push(`/${lang}${routes[prefixes.confirmPhone].path}`);
            }
        },
        onError: (error: IRequestError) => {
            const code = getAPIErrorCode(error);
            const errorField = VALIDATION_ERROR_CODES[code as keyof typeof VALIDATION_ERROR_CODES];

            if (code && errorField) {
                formik.setFieldError(errorField.field, errorField.error);
                return;
            }

            toast.error(t('DEFAULT_ERROR_MESSAGE'));
        },
    });

    const formik = useFormik({
        initialValues: {
            [username.name]: '',
            [password.name]: '',
        } as unknown as ILoginData,
        validationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        validateOnMount: true,
        onSubmit: (values: ILoginData) => {
            mutate(values);
        },
    });

    return (
        <div className="flex flex-col h-full justify-center">
            <p className="text-title text-black-100 mb-24">{t('TITLE')}</p>
            <form
                onSubmit={formik.handleSubmit}
                onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                        formik.handleSubmit();
                    }
                }}
                noValidate
            >
                <Input
                    name={username.name}
                    label={t(username.label)}
                    placeholder={t(username.placeholder)}
                    onChange={formik.handleChange}
                    value={formik.values.username}
                    error={t(formik.errors.username || '')}
                    touched={formik.touched.username}
                    id={username.id}
                    required
                />
                <Input
                    name={password.name}
                    label={t(password.label)}
                    placeholder={t(password.placeholder)}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    error={t(formik.errors.password || '')}
                    touched={formik.touched.password}
                    type="password"
                    showPasswordSwitch
                    id={password.id}
                    required
                />
                <Link href={`/${lang}/${routes[prefixes.forgotPassword].path}`} className="text-xsm-title text-green-100">
                    {t('FORGOT_PASSWORD')}
                </Link>
                <div className="mt-32">
                    <Button variant="success" className="w-full" type="submit" disabled={isLoading || !formik.isValid} showLoader={isLoading} showDisabled>
                        {t('SUBMIT_BTN')}
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default LoginForm;
