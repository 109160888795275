import * as yup from 'yup';

import { FIELDS, MFA_FIELDS } from 'app/[locale]/login/constants/login-constants';

const validate = () => {
    const { username, password } = FIELDS;

    return yup.object().shape({
        [password.name]: yup.string().required(password.errorRequired),
        [username.name]: yup.string().required(username.errorRequired),
    });
};

const validateOtp = () => {
    const { otp } = MFA_FIELDS;

    return yup.object().shape({
        [otp.name]: yup.string().trim().required(otp.errorRequired).min(6, otp.errorRequired),
    });
};

export const validationSchema = validate();

export const otpValidationSchema = validateOtp();
