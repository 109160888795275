'use client';

import { useState } from 'react';

import MFAForm from 'app/[locale]/login/components/mfa-form';
import LoginForm from 'app/[locale]/login/components/login-form';

interface IProps {
    lang: string;
    redirectUrl?: string;
}

const LoginWrapper = ({ lang, redirectUrl }: IProps) => {
    const [isMFAForm, setIsMFAForm] = useState<boolean>(false);
    const [authToken, setAuthToken] = useState<string>('');

    if (isMFAForm) {
        return <MFAForm lang={lang} redirectUrl={redirectUrl} authToken={authToken} />;
    }

    return <LoginForm lang={lang} redirectUrl={redirectUrl} setIsMFAForm={setIsMFAForm} setAuthToken={setAuthToken} />;
};

export default LoginWrapper;
