import OnboardingArticleImg from 'common/images/onboarding-article.svg';
import { IArticle } from 'components/onboarding-article/types';

export const ARTICLE: IArticle = {
    image_url: OnboardingArticleImg,
    title: 'FIND_THINGS',
    details: {},
};

export const FIELDS = {
    username: {
        name: 'username',
        label: 'USERNAME_LABEL',
        placeholder: 'USERNAME_PLACEHOLDER',
        errorRequired: 'USERNAME_REQUIRED',
        id: 'login-username',
    },
    password: {
        name: 'password',
        label: 'PASSWORD_LABEL',
        placeholder: 'PASSWORD_PLACEHOLDER',
        errorRequired: 'PASSWORD_REQUIRED',
        id: 'login-password',
    },
};

export const VALIDATION_ERROR_CODES = {
    'USERNAME.010': {
        field: FIELDS.password.name,
        error: 'INVALID_CREDENTIALS',
    },
    'PASSWORD.020': {
        field: FIELDS.password.name,
        error: 'INVALID_CREDENTIALS',
    },
};

export const MFA_FIELDS = {
    otp: {
        name: 'otp',
        errorRequired: 'OTP_REQUIRED',
    },
};

export const MFA_ERROR_CODES = {
    'TPA.021': {
        field: MFA_FIELDS.otp.name,
        error: 'MFA_OTP_WRONG',
    },
};
