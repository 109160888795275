'use client';

import LZUTF8 from 'lzutf8';
import AuthCode from 'react-auth-code-input';
import { useFormik } from 'formik';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

import { useTranslation } from 'i18n/client';
import { MFA_FIELDS, MFA_ERROR_CODES } from 'app/[locale]/login/constants/login-constants';
import { Button } from 'components';
import { setSession } from 'core';
import { routesConstants, helpers } from 'common';
import { authAPI, userAPI } from 'api';
import { otpValidationSchema } from 'app/[locale]/login/validators/login-validator';

interface IProps {
    lang: string;
    redirectUrl?: string;
    authToken: string;
}

interface IFormValues {
    otp: string;
}

const { otp } = MFA_FIELDS;
const { verifyMFA } = authAPI;
const { getUserDetailsAPI } = userAPI;
const { routes, prefixes } = routesConstants;
const { getAPIErrorCode, cn } = helpers;

const MFAForm = ({ lang, redirectUrl, authToken }: IProps) => {
    const router = useRouter();
    const { t } = useTranslation(lang, 'login');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleMFA = async (code: string) => {
        setIsLoading(true);
        try {
            const res = await verifyMFA(code, authToken);

            const accessToken = res.accessToken || '';
            const refreshToken = res?.refreshToken || '';
            const response = await getUserDetailsAPI({
                Authorization: `Bearer ${accessToken}`,
            });
            const userDetails = response?.data;

            setIsLoading(false);
            setSession(accessToken, refreshToken, userDetails);

            router.refresh();

            // if the user has phone verified, redirect to home page, else redirect
            // to the phone verification page to force him/her to verify the phone number
            if (userDetails.phoneVerified) {
                const path = redirectUrl ? decodeURIComponent(LZUTF8.decompress(redirectUrl, { inputEncoding: 'Base64' })) : `/${lang}${routes[prefixes.home].path}`;
                router.push(path);
            } else {
                router.push(`/${lang}${routes[prefixes.confirmPhone].path}`);
            }
        } catch (error: any) {
            setIsLoading(false);
            const code = getAPIErrorCode(error);
            const errorField = MFA_ERROR_CODES[code as keyof typeof MFA_ERROR_CODES];

            if (code && errorField) {
                formik.setFieldError(errorField.field, errorField.error);
                return;
            }

            toast.error(t('DEFAULT_ERROR_MESSAGE'));
        }
    };

    const formik = useFormik({
        initialValues: {
            [otp.name]: '',
        } as unknown as IFormValues,
        validationSchema: otpValidationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        validateOnMount: true,
        onSubmit: (values: IFormValues) => {
            handleMFA(values.otp);
        },
    });

    return (
        <div className="flex flex-col h-full justify-center">
            <p className="text-title text-black-100 mb-24">{t('MFA_TITLE')}</p>
            <p className="text-[14px] text-grey-300 font-light mb-24">{t('MFA_MESSAGE')}</p>
            <form
                onSubmit={formik.handleSubmit}
                onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                        formik.handleSubmit();
                    }
                }}
                noValidate
            >
                <div className="pb-24">
                    <AuthCode
                        autoFocus
                        allowedCharacters="numeric"
                        length={6}
                        onChange={(value: string) => {
                            formik.setFieldValue(otp.name, value, true);
                            formik.setFieldTouched(otp.name, true);
                            formik.validateForm({ otp: value });
                        }}
                        inputClassName={cn(
                            'text-input border-b border-b-grey-200 py-16 text-center outline-none',
                            formik.errors.otp && formik.touched.otp ? 'border-b-red-100 text-red-100' : 'border-b-grey-200 text-black-100',
                        )}
                        containerClassName="grid grid-cols-6 gap-16"
                    />
                    {formik.errors.otp && formik.touched.otp && (
                        <div className="relative mt-8">
                            <p className="text-error text-red-100 absolute top-[-4px]">{t(formik.errors.otp)}</p>
                        </div>
                    )}
                </div>
                <div className="mt-32">
                    <Button variant="success" className="w-full" type="submit" disabled={isLoading || !formik.isValid} showLoader={isLoading} showDisabled>
                        {t('MFA_BUTTON')}
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default MFAForm;
